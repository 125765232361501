

















































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Document } from "@/models/document.model";
import { EsService } from "@/services/es.service";
import { SearchData } from "@/models/search.model";
import { Structure } from "@/models/structure.model";
import { StructureElement } from "@/models/structure.element.model";
import JnProgress from "@/views/common/Progress.vue";
import JnFacebookSharing from "@/views/sharing/FacebookSharing.vue";
import JnLinkedinSharing from "@/views/sharing/LinkedinSharing.vue";
import JnPrint from "@/views/common/Print.vue";
import JnWord from "@/views/common/Word.vue";
import JnPaywall from "@/views/auth/Paywall.vue";
import { CategoriesGiurisprudenza } from "@/models/enums.model";
import EventBus from "@/services/event.bus";
import { CrmInfo } from "@/models/crm/crmInfo.model";
import { JnService } from "@/services/jn.service";
import { DossierDocument } from "@/models/dossier.document.model";
import { uuid } from "vue-uuid";
import { Dossier } from "@/models/dossier.model";
import MetaInfo from "vue-meta";

@Component({
  components: {
    JnProgress,
    JnFacebookSharing,
    JnLinkedinSharing,
    JnPrint,
    JnWord,
    JnPaywall,
  },
  metaInfo(this: JnJuranetDoc): MetaInfo {
    return { title: "JuraNews - " + this.doc?.title };
  },
})
export default class JnJuranetDoc extends Vue {
  @Prop({ required: true })
  id!: string;

  @Prop({ required: false })
  target?: string;

  @Prop({ required: false })
  type?: string;

  loading = true;
  hasFragments = false;
  isMounted = false;

  keywords: string | undefined;
  queryType: string | undefined;
  showHighlight = false;

  MAX_SIZE = 200000;

  doc? = new Document({
    id: "0",
    seq: 0,
    target: "",
    type: "",
    isFragment: false,
    title: "",
    imageSrc: "",
    abstract: "",
    date: "",
    tags: [],
    categories: [],
    idCategory: "",
    idAreaTematica: "",
    areaTematica: "",
    idMateria: "",
    idArgomento: "",
    materia: "",
    argomento: "",
    author: "",
    descrAuthor: "",
    size: 0,
    highlights: [],
    index: "",
    access: 1,
    since: "",
    to: "",
    sezioneCassazione: "",
    abrogato: false,
    note: [],
  });

  numArticle = "";

  paywallOk = false;
  selection: any[] = [];
  structure: Structure[] = [];
  selectedFragments: StructureElement[] = [];
  loadingSelection: any[] = [];

  alert = false;
  alertMessage = "";
  alertTimeout = 10000;

  structureOpened = false;

  get authenticated() {
    return this.$store.getters.crmInfo;
  }

  isFragment = this.target != undefined;

  docSearchData = new SearchData({
    index: process.env.VUE_APP_JURANET_INDEXES!,
    id: this.isFragment ? this.id.split("#")[0] : this.id,
    target: this.target,
    type: this.type,
    size: 1,
    from: 0,
    withText: false,
  });

  get hasSelectedFragments() {
    return this.selection.length > 0;
  }

  openStructure() {
    this.structureOpened = true;
  }

  closeStructure() {
    this.structureOpened = false;
  }

  /**
   * Quando il documento  è caricato, ne costruisco la struttura corrispondente ai tag Jura
   */
  async initialLoad() {
    /* console.log(
      'JuranetDoc.onDocumentChanged(newDocument): ' +
        JSON.stringify(newDocument)
    );*/
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANET_FRAGMENTS_INDEXES!,
      id: this.doc!.id,
      size: 1,
      from: 0,
      withText: true,
    });
    if (this.doc!.id !== "0") {
      // Recupera la struttura del documento
      const structure = await EsService.getStructure(searchData);
      /*console.log(
        'JuranetDoc.initialLoad(structure): ' + JSON.stringify(structure)
      );*/
      try {
        // Se da ES non esiste una struttura, il documento ne è privo
        if (!structure) {
          this.hasFragments = false;
        } else {
          const jsonStructure = JSON.parse(
            structure.hits.hits[0]._source.structure
          );
          const struct = new Structure({
            id: this.doc!.id,
            docId: this.doc!.id,
            name: this.doc!.title,
            idCategory: this.doc!.idCategory,
            jsonStructure: jsonStructure,
          });
          /*    console.log(
            'JuranetDoc.initialLoad(struct): ' + JSON.stringify(struct)
          );*/
          // Se da ES esiste una struttura, ma non ci sono figli, il doc non ha frammenti
          if (struct.getChildren().length == 0) {
            this.hasFragments = false;
          } else {
            this.hasFragments = true;
          }
          this.structure.push(struct);
        }
      } catch (error) {
        console.log(error);
        console.log("JuranetDoc.initialLoad(error): " + JSON.stringify(error));
      }

      if (this.hasFragments) {
        // Se non si tratta di un fragment
        if (!this.doc!.type) {
          const firstElement = this.structure[0].getChildren()[0];
          // Se il documento supera la dimensione soglia
          if (firstElement && this.doc!.size >= this.MAX_SIZE) {
            this.loadingSelection.push(firstElement);
          }
          // Se il documento non documento supera la dimensione soglia
          else {
            this.loadingSelection.push(this.structure[0]);
          }
        }
        // Se si tratta di un fragment
        else {
          const id =
            this.doc!.id + "#" + this.doc!.target + "#" + this.doc!.type;
          const element = this.structure[0].getChild(id);
          if (element) {
            this.loadingSelection.push(element);
          }
        }
      } else {
        this.loadingSelection.push(this.structure[0]);
      }
    }
  }

  async updateSelectedFragments(event: StructureElement[]) {
    this.structureOpened = false;
    if (this.loading) {
      this.selection = this.loadingSelection;
    }
    this.selectedFragments = [];
    for (const e of this.selection) {
      console.log(e.id);
      if (this.doc && e.id !== this.doc.id && !e.text) {
        const searchData = new SearchData({
          index: process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_INDEXES!,
          id: e.id,
          size: 1,
          from: 0,
          withText: true,
        });
        if (e.version > 0) {
          searchData.index =
            process.env.VUE_APP_JURANET_FRAGMENTS_FISCAL_HISTORY_INDEXES!;
        }
        this.loading = true;
        const fragment = await EsService.getDoc(searchData);
        this.loading = false;
        if (fragment) {
          e.since = fragment.since;
          e.to = fragment.to;
          e.text = fragment.text;
        }
      } else {
        if (!e.text) {
          const searchData = new SearchData({
            index: process.env.VUE_APP_JURANET_INDEXES!,
            id: e.id,
            size: 1,
            from: 0,
            withText: true,
          });
          this.loading = true;
          this.doc!.text = (await EsService.getDoc(searchData))!.text;
          this.loading = false;
          e.text = this.doc!.text;
        }
      }
      this.insertFragment(e);
    }

    if (!this.selection.length) {
      this.showAlert(
        "Selezionare una o più parti dall'albero a sinistra",
        "info"
      );
    }
  }

  async mounted() {
    const usernameFromCookie = this.$cookies.get("username");
    console.log(
      "JuranetDoc.mounted(usernameFromCookie): " + usernameFromCookie
    );
    if (usernameFromCookie) {
      const user = await JnService.getCrmUser(
        String(usernameFromCookie),
        undefined
      );
      const purchase = await JnService.getActivePurchaseOrder(user.id);
      let crmInfo = undefined;
      console.log("JuranetDoc.mounted(purchase): " + JSON.stringify(purchase));
      if (purchase) {
        this.paywallOk = true;
        const service = await JnService.getService(purchase.serviceId);
        crmInfo = new CrmInfo(user, purchase, service);
      } else {
        this.paywallOk = false;
        crmInfo = new CrmInfo(user, undefined, undefined);
      }
      this.$store.commit("crmInfo", crmInfo);
    }

    EventBus.$on("doc-create-start", () => {
      this.loading = true;
    });
    EventBus.$on("doc-create-stop", () => {
      this.loading = false;
    });
    EventBus.$on("logged-paywall-ok", () => {
      this.paywallOk = true;
    });
    EventBus.$on("logged-paywall-not-ok", () => {
      this.paywallOk = false;
    });
    const tmpDoc = await EsService.getDoc(this.docSearchData);

    if (!tmpDoc) {
      this.loading = false;
    }

    if (tmpDoc!.size < this.MAX_SIZE) {
      this.docSearchData.withText = true;
    }

    this.doc = await EsService.getDoc(this.docSearchData);

    await this.initialLoad();

    // Manca da sistemare la parte di impostare il frammento nell'albero se
    // si arriva da link esterno diretto su un elemento dell'albero

    const selectedArticle = this.$cookies.get("selectedArticle");
    this.numArticle = selectedArticle
      ? String(selectedArticle)
      : this.numArticle;
    this.$cookies.remove("selectedArticle");
    if (this.numArticle) {
      this.updateSelectedArticle();
      this.loadingSelection = this.selection;
      // console.log("NUM ARTICLE: " + this.numArticle);
      console.log(this.loadingSelection);
    }
    await this.updateSelectedFragments(this.loadingSelection);

    this.keywords = String(this.$route.query.keywords);
    this.queryType = String(this.$route.query.queryType);

    this.isMounted = true;
    this.showHighlight = true;

    await JnService.addAccessDocStat(
      usernameFromCookie ? (usernameFromCookie as string) : "",
      this.doc?.id!,
      window.location.href
    );
  }

  insertFragment(element: StructureElement): StructureElement | undefined {
    for (const e of this.selectedFragments) {
      if (e.id === element.id) {
        e.text = element.text;
        return e;
      }
    }
    this.selectedFragments.push(element);
  }

  showAlert(message: string, type: string) {
    this.alert = true;
    this.alertMessage = message;
  }

  get isGiurisprudenza(): boolean {
    if (this.doc?.idCategory) {
      const isGiurisprudenza = CategoriesGiurisprudenza.includes(
        String(this.doc.idCategory)
      );
      return isGiurisprudenza;
    }
    return false;
  }

  @Watch("numArticle")
  async updateSelectedArticle() {
    console.log(this.numArticle);
    const structureElements = this.structure[0].getChildrenByArticleNumber(
      this.numArticle!
    );
    this.selection = structureElements;
    console.log(structureElements);
  }

  @Watch("showHighlight")
  async switchShowHighlight() {
    this.selectedFragments = [];
    for (const e of this.selection) {
      if (this.keywords!.trim() != "") {
        if (this.queryType != "EXACT") {
          this.keywords = this.keywords
            ?.replaceAll(" i ", " ")
            .replaceAll(" I ", " ")
            .replaceAll(" a ", " ")
            .replaceAll(" A ", " ")
            .replaceAll(" e ", " ")
            .replaceAll(" E ", " ")
            .replaceAll(" o ", " ")
            .replaceAll(" O ", " ")
            .replaceAll(" del ", " ")
            .replaceAll(" dei ", " ")
            .replaceAll(" delle ", " ")
            .replaceAll(" della ", " ")
            .replaceAll(" un ", " ")
            .replaceAll(" una ", " ")
            .replaceAll(" in ", " ")
            .replaceAll(" il ", " ")
            .replaceAll(" lo ", " ")
            .replaceAll(" la ", " ")
            .replaceAll(" di ", " ")
            .replaceAll(" da ", " ");
        }
        const words = this.queryType == "EXACT" ? this.keywords!.trim().split("§§") : this.keywords?.split(" ");

        for (const w of words!) {
          if (this.showHighlight) {
            const reg = new RegExp(w, "ig");
            e.text = e.text!.replace(reg, "<mark>" + w + "</mark>");
          } else {
            const strReplace = "<mark>" + w + "</mark>";
            const reg = new RegExp(strReplace, "ig");
            e.text = e.text!.replace(reg, w);
          }
        }
      }
      this.insertFragment(e);
    }
  }

  /**
   * GESTIONE DOSSIER - INIZIO
   **/
  dossier = new Dossier(String(this.$cookies.get("username")), []);
  targetDossier!: DossierDocument | undefined;
  showAdd = false;

  async selectTargetDossier(selectedDocument: DossierDocument[]) {
    const targetDossierDocument = selectedDocument[0];
    this.targetDossier = targetDossierDocument;
  }
  async addToDossier() {
    const username = String(this.$cookies.get("username"));
    let addDirectToDossier = false;
    try {
      this.dossier = await JnService.getDossier(username);
      if (this.dossier.hasDossier()) {
        this.showAdd = true;
      } else {
        addDirectToDossier = true;
      }
    } catch (err) {
      addDirectToDossier = true;
    }
    if (addDirectToDossier) {
      const newDossierDocument = new DossierDocument(
        "",
        this.doc?.title,
        this.doc?.text,
        [],
        false
      );
      const response = await JnService.saveDossierDocument(newDossierDocument);
      newDossierDocument.id = response.id;
      const newDossier = new DossierDocument(
        uuid.v1(),
        "Nuovo fascicolo",
        undefined,
        [],
        true
      );
      newDossier.addDoc(newDossierDocument);
      this.dossier.addDoc(newDossier);
      this.alertMessage =
        "Il documento è stato aggiunto al fascicolo 'Nuovo fascicolo'. Accedi all'area utente per gestire i tuoi fascicoli";
      this.alert = true;
      this.dossier = await JnService.saveDossier(this.dossier);
      EventBus.$emit("dossier", this.dossier);
    }
  }
  async saveAdd() {
    if (this.targetDossier && !this.targetDossier.isDossier) {
      this.alertMessage = "Selezionare un fascicolo.";
      this.alert = true;
    } else {
      const newDossierDocument = new DossierDocument(
        "",
        this.doc?.title,
        this.doc?.text,
        [],
        false
      );
      const response = await JnService.saveDossierDocument(newDossierDocument);
      newDossierDocument.id = response.id;
      this.showAdd = false;
      if (!this.targetDossier) {
        const nuovoFascicolo = new DossierDocument(
          uuid.v1(),
          "Nuovo fascicolo",
          undefined,
          [],
          true
        );
        nuovoFascicolo.addDoc(newDossierDocument);
        this.dossier.addDoc(nuovoFascicolo);
        this.alertMessage =
          "Il documento è stato aggiunto al fascicolo 'Nuovo fascicolo'. Accedi all'area utente per gestire i tuoi fascicoli";
        this.alert = true;
      } else {
        this.targetDossier.addDoc(newDossierDocument);
        this.alertMessage =
          "Il documento è stato aggiunto al fascicolo '" +
          this.targetDossier.name +
          "'. Accedi all'area utente per gestire i tuoi fascicoli";
        this.alert = true;
      }
      this.dossier = await JnService.saveDossier(this.dossier);
      EventBus.$emit("dossier", this.dossier);
    }
  }
  /**
   * GESTIONE DOSSIER - FINE
   **/
}
